.detailDrawer-wrap {
  padding: 16px;

  .detail-row {
    display: flex;
    margin-bottom: 12px;

    .detail-label {
      font-weight: bold;
      width: 150px; // 设置标签的宽度
      margin-right: 8px;
    }

    .detail-value {
      flex: 1;
      word-break: break-word; // 防止长单词或链接溢出
    }
  }

  .contact-detail {
    margin-bottom: 12px;

    div {
      margin-bottom: 4px;
    }
  }

  h2 {
    margin-top: 24px;
    border-bottom: 1px solid #eee;
    padding-bottom: 8px;
  }

  .formItemVertical {
    .ant-form-item-row {
      flex-direction: column !important;
      align-items: flex-start;
    }

    .ant-col {
      text-align: left;
      width: 100%;
    }
  }
}
