.sider-logo-container {
  color: white;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .sider-title {
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin-left: 16px;
  }

  .sider-icon {
    font-size: 24px;
    color: white;
  }
}

.horizontal-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.horizontal-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.horizontal-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


body, html, #root, .ant-layout {
  height: 100%;
  margin: 0;
}
#root {
}
