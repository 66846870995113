
.contact_wrap {

  .card {
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    .card-left {
      flex: 1;
      p {
        margin: 0;
        padding: 0;
      }
    }
    .card-right {
      color: #a21b1b;
      width: 36px;
      text-align: right;
      flex-shrink: 0;
      span {
        cursor: pointer;
      }
    }
  }

  .remark {
    font-size: 14px;
    padding: 0;
    margin-top: 6px;
    color: #999999;
  }
}
