
.login-wrap {
  width: 100%;
  min-width: 1336px;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: row;
  .left {
    width: calc(100% - 450px);
    height: 100%;
    overflow: hidden;
    flex: 1;
    background-color: #B60408;
    .logo-left {
      width: 280px;
      margin: 45px 0 50px 42px;
    }
    .background {
      width: 100%;
    }
  }
  .right {
    flex-shrink: 0;
    width: 450px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    .logo {
      margin-left: 54px;
      width: 280px;
    }
    .form {
      margin-top: 24px;
      padding: 0 54px;
      .login-form-button {
        width: 100%;
        background-color: #B60408;
        border-color:#B60408;
      }
    }
  }
}
