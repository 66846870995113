.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  line-height: 80px;
  background-color: #fff;
  text-align: center;

  align-items: flex-start;
  padding: 15px 20px;
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 90px;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px;
}
