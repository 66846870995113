@import '../../theme';

.home-wrap {
  height: 100%;

  .navigator {
    width: 100%;
    height: 56px;
    background-color: $primary-color;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-left {
      width: 116px;
      height: 36px;
    }
  }

  .content {
    overflow: hidden;
    display: flex;
    height: 100%;

    .left-part {
      flex-shrink: 0;

      .left-menu {
        padding-top: 20px;
        height: 100%;
      }
      .ant-menu-item.ant-menu-item-selected {
        color: $primary-color;
      }
      .ant-menu-light .ant-menu-item:hover {
        color: $primary-color;
      }
      .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: $primary-light-color;
      }
      .ant-menu-inline .ant-menu-item:after {
        border-right-color: $primary-color;
      }
    }

    .right-part {
      overflow: auto;
      flex: 1;
      padding: 24px;
    }
  }
}

.navigator-dropdown-menu {
  .navigator-item {
    .navigator-user-icon {
      margin-right: 10px;
      width: 22px;
      height: 22px;    }
    .navigator-icon {
      padding: 2px;
      margin-right: 10px;
      width: 22px;
      height: 22px
    }
  }

  .ant-dropdown-menu {
    padding: 8px 12px 16px;
  }
}
