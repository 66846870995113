
.appointment-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;

  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 32px;
  }
  .actions-cell {
    span {
      cursor: pointer;
    }
    .success {
      color: #00b578;
    }
    .cancel {
      color: #666666;
    }
    .danger {
      margin-left: 8px;
      color: #B60408;
    }
  }
}
