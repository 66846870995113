
.applyManager-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-btn-primary {
    border-color: #B60408;
    background-color: #B60408;

    &:hover,
    &:focus {
      border-color: lighten(#B60408, 10%);
      background-color: lighten(#B60408, 10%);
    }
  }

  .ant-pagination-item-active {
    border-color: #B60408;
    a {
      color: #B60408;
    }

    &:hover {
      border-color: lighten(#B60408, 10%);
      a {
        color: lighten(#B60408, 10%);
      }
    }

    &:focus {
      border-color: lighten(#B60408, 10%);
      a {
        color: lighten(#B60408, 10%);
      }
    }
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 32px;
  }

  .actions-cell {
    span {
      cursor: pointer;
    }

    .success {
      color: #00b578;
    }

    .cancel {
      color: #666666;
    }

    .danger {
      margin-left: 8px;
      color: #B60408;
    }
  }
}
